.calendarIcon {
  margin-right: 5px;
}

.errorContainer {
  margin-left: 20px;
  padding-left: 16px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.singleSelectErrorContainer {
  margin-top: -20px;
}

.errorIcon {
  margin-right: 5px;
  padding-top: 3px;
  color: #be2f38;
}

.errorText {
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  color: #be2f38;
}

.tableRowTitle {
  padding-top: 18px;
  padding-bottom: 18px;
  border-width: 0.8px;
  border-style: solid;
  width: 300px;
  border-color: #e9e9ea; /*Boron Color GrayscalePalette[300]*/
  background-color: #f9f9fa; /*Boron Color GrayscalePalette[200]*/
}

.tableRowTitleText {
  font-weight: 500;
}

.tableRowSubText {
  font-weight: 500;
  margin-top: 10px;
  font-size: 12px;
}

.tableRowTitleUl {
  margin: 0
}

.fieldRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.datePickerRoot { }

.datePickerRoot > div > div {
  height: 100%;
}

.inputRoot {
  min-height: 75px;
  height: 100%;
  flex-grow: 1;
}

.inputWrapper {
  height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.inputInput {
  padding-left: 36px !important;
}

.inputInput::placeholder {
  white-space: initial;
}

/* Keep in sync with .singleSelectTypeaheadOuterContainerError .singleSelectTypeaheadValueContainer */
.textInputInputError {
  padding-left: 36px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

/* Keep in sync with .singleSelectTypeaheadOuterContainerError .singleSelectTypeaheadValueContainer::placeholder */
.textInputInputError::placeholder {
  white-space: initial;
}

/* Hide input for error in FormSignatureWithDate */
.textInputInputErrorHidden {
  display: none;
}

.textAreaInput {
  field-sizing: content;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px 12px 36px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textAreaInputRoot > div:nth-child(1) {
  height: 75px !important;
}

.textAreaInputRootError > div:nth-child(1) {
  height: 52px !important;
}

.checkboxLabel {
  margin-left: 20px;
  padding: 8px 8px 8px 8px;
}

.multiSelectCheckboxLabel {
  margin-left: 20px;
  padding: 4px 8px 8px 4px;
}

.singleSelectDropdownValueContainer {
  height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 12px 20px 12px 16px !important;
  margin-left: 20px;
  background-color: transparent !important;
}

.singleSelectDropdownValue {
  font-family: Arial !important;
  font-size: 14px !important;
}

.singleSelectValueDropdownContainerError {
  height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 12px 20px 0px 16px !important;
  margin-left: 20px;
}

.singleSelectDropdownValueError {
  font-family: Arial !important;
  font-size: 14px !important;
  margin-bottom: 0px;
}

.singleSelectDropdownMenu {
  position: absolute !important;
  left: -10px !important;
}

.singleSelectTypeaheadOuterContainerError .inputWrapper {
  /* .textInputError */
  background-color: #ffd2d2 !important;
}

.singleSelectTypeaheadOuterContainer .singleSelectTypeaheadValueContainer {
  height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 12px 20px 12px 34px !important;
}

.singleSelectTypeaheadOuterContainerError .singleSelectTypeaheadValueContainer {
  height: 100% !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 12px 20px 4px 16px !important;
  margin-left: 20px;
  /* .textInputError */
  background-color: #ffd2d2 !important;
  /* .textInputInputError */
  padding-left: 36px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.singleSelectTypeaheadOuterContainerError .singleSelectTypeaheadValueContainer::placeholder {
  white-space: initial;
}

.searchBox {
  width: auto !important;
}

.singleTypeaheadSearchBox {
  top: 28.5px !important;
}

.singleTypeaheadSearchBoxError {
  top: 10px !important;
}

.singleTypeaheadShowPlaceholder {
  opacity: 1 !important;
}

.singleTypeaheadHidePlaceholder {
  opacity: 0 !important;
}

.textPlaceholder {
  text-overflow: hidden;
}

.dropdownPlaceholder {
  color: #77777c !important;
  font-size: 12px !important;
}

/* Keep in sync with .singleSelectTypeaheadOuterContainerError .inputWrapper and .singleSelectTypeaheadOuterContainerError .singleSelectTypeaheadValueContainer  */
.textInputError {
  background-color: #ffd2d2 !important;
}
.checkboxContainer {
  min-width: 16px !important;
}

.singleSelectDropdownContainer {
  margin: 30px;
  display: block;
  flex-direction: column;
}

.signatureBox {
  margin: 30px;
  width: calc(100% - 62px);
  height: 100px;
  display: flex;
  border-width: 1px;
  border-style: solid;
  flex-direction: column;
}

.signatureBoxWithDate {
  margin-bottom: 0px !important;
}

.signatureBoxWithDateDateLabel {
  margin-top: auto;
  margin-bottom: auto;
}

.signatureBoxWithDateInputWrapper {
  border: none !important;
  width: fit-content !important;
}

.signatureBoxWithDateInputRoot {
  margin: 30px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: row;
}

.signatureBoxWithDateInput {
  padding-left: 10px !important;
}

.signatureInnerBox {
  width: calc(100% - 40px);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-left: 20px;
  height: 80px;
}

.signatureCanvas {
  width: calc(100% + 40px);
  margin-left: -20px;
  height: calc(100% + 20px);
}

.bold {
  font-weight: 700;
}

.removeLinkColor {
  color: #000000;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 30px;
}

.header {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  color: #ffffff; /*ActionPalette[100]*/
  box-shadow: 0px 2px 4px #e9e9ea; /*GrayscalePalette[300]*/
}

.headerLogo {
  margin-left: 80px;
}

.screen {
  display: flex;
  flex-direction: column;
}

.section {
  margin-bottom: 10px;
}

.title {
  margin-top: 60px;
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.subtitle {
  margin-top: 4px;
  margin-bottom: 40px;
}

.checklistHeader {
  margin-bottom: 20px;
}

.footer {
  margin-top: 30px;
  text-align: end;
}

.formBanner {
  margin-top: 25px;
  margin-bottom: 25px;
}

.backButton {
  float: left;
}

.lockedOutContainer {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.lockedOutHeader {
  padding-top: 30px;
}

.lockedOutBody {
  padding-top: 8px;
}

.statusBadge {
  text-align: left;
  padding-top: 8px;
  padding-left: 18px;
}

.badgeIcon {
  width: 10px !important;
  height: 10px !important;
}

.badgeRoot {
  width: max-content !important;
}

.badgeTooltip {
  position: relative !important;
  left: 0px !important;
  top: 6px !important;
}

.formAlert {
  margin-top: 25px;
  margin-bottom: 25px;
}

.successAlertRoot {
  width: 100%;
  max-width: none !important;
}

.alertContent {
  white-space: initial;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 40px;
  border-width: 0.8px;
  border-style: solid;
  border-collapse: collapse;
  width: 100%;
  border-color: #e9e9ea; /*Boron Color GrayscalePalette[300]*/
}

.tableRow {
  /* min-height: 70px; */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.tableCell {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: calc(100% - 302px);
}


.tableRowTitle {
  border-width: 0.8px;
  border-style: solid;
  width: 300px;
  border-color: #e9e9ea; /*Boron Color GrayscalePalette[300]*/
  background-color: #f9f9fa; /*Boron Color GrayscalePalette[200]*/
}

.tableRowLabelContainer {
  padding-left: 18px;
  padding-right: 18px;
  align-content: center;
}

.tableRowSubText {
  font-size: 12px;
}

.tableRowTitleText {
  font-weight: 500;
}

.tableRowContent {
  border-width: 0.8px;
  border-style: solid;
  padding: 0px;
  vertical-align: bottom;
  border-color: #e9e9ea; /*Boron Color GrayscalePalette[300]*/
  flex-grow: 1;
}

.tableRowContentFocusArea:focus-within {
  border-style: solid;
  border-width: 1.5px;
  border-radius: 3px;
  border-color: #206EF3; /* Boron Color ActionPalette[300]*/
}

/* Minor UI updates for mobile screens */
@media (max-width: 480px) {
  .screen {
    min-width: 360px;
  }
  .content {
    padding-left: 24px;
    padding-right: 24px;
    min-width: 360px;
  }
  .headerLogo {
    margin-left: 24px;
  }

  .tableRowTitle {
    padding-top: 5px;
    padding-bottom: 5px
  }
  .tableRowSubText {
    margin-top: 0;
  }

  .tableRow {
    flex-direction: column;
  }

  .tableCell {
    width: 100% !important;
    max-width: 100% !important;
  }
  .tableRowTitle {
    margin-right: 12px;
  }
  .statusBadge {
    padding-left: 12px;
  }
  .tableRowLabelContainer {
    padding-left: 12px;
    padding-right: 12px;
  }
  /* Hide empty label divider when mobile */
  .tableRowLabelContainerEmpty {
    display: none;
  }

  .signatureBox {
    width: calc(100% - 26px);
    margin: 12px;
    min-width: 240px;
  }

  .signatureBoxWithDateInputRoot {
    margin: 12px;
  }

  .textAreaInputRoot > div:nth-child(1) {
    height: auto !important;
  }

  .textAreaInput {
    padding: 12px 24px !important;
  }
}

/* Minor UI updates for tablet screens */
@media (max-width: 1024px) and (min-width: 481px) {
  .content {
    padding-left: 48px;
    padding-right: 48px;
  }
  .headerLogo {
    margin-left: 48px;
  }
  .tableRowTitle {
    width: 192px;
  }

  .tableCell {
    max-width: calc(100% - 194px);
  }

  .textAreaInputRoot > div:nth-child(1) {
    height: auto !important;
  }

  .textAreaInput {
    padding: 12px 24px !important;
  }
}
